<script setup lang="ts">
// from https://github.com/arabsight/aurelia-quill-plugin
// noinspection JSUnusedGlobalSymbols
import Quill, { type QuillOptionsStatic } from "quill"
import { onMounted, onUnmounted, ref, watch } from "vue"

const props = defineProps<{
    modelValue?: string
    options?: Partial<QuillOptionsStatic>
    enableShowSource?: boolean
    context?: any
}>()

const emit = defineEmits<{
    (e: "update:modelValue", value: string): void
}>()

/** The bound value of the input which is delegated to the outer v-model */
const value = ref<string>(props.modelValue ?? props.context?.value ?? "")

/* Editor HTML element */
const quillEditor = ref<HTMLDivElement | null>(null)
const quillToolbar = ref<HTMLDivElement | null>(null)
let editor: Quill | null = null
let textChanged = false

// tell quill to add the "img-fluid" class on all images that are inserted
var Image = Quill.import("formats/image")
Image.className = "img-fluid"
Quill.register(Image, true)

/*─────────────────────────────────────┐
│   show source                        │
└─────────────────────────────────────*/
const showSource = ref(false)

function toggleShowSource() {
    showSource.value = !showSource.value

    if (!showSource.value) {
        valueChanged(value.value, undefined)
    }
}

/*─────────────────────────────────────┐
│   change handlers                    │
└─────────────────────────────────────*/
function valueChanged(newValue: string, oldValue: string | undefined) {
    if (!editor) {
        return
    }

    if (newValue !== oldValue && editor.root.innerHTML !== newValue && textChanged === false && !showSource.value) {
        if (newValue || newValue === "") {
            let html: string
            html = value.value

            editor.root.innerHTML = html.replace(/>\s</g, "><")
        }
    }

    emit("update:modelValue", newValue)
    textChanged = false
}

function onTextChanged() {
    textChanged = true
    let out = editor?.root.innerHTML

    value.value = out ?? ""
    if (props.context) {
        props.context.node.input(out)
    }
}

// value has been changed in the editor
watch(value, valueChanged)

// value has been changed from outside
watch(
    () => props.modelValue,
    () => {
        value.value = props.modelValue ?? ""
    },
)

/*─────────────────────────────────────┐
│   setup                              │
└─────────────────────────────────────*/
onMounted(() => {
    let editorConfig = {
        modules: {
            toolbar: quillToolbar.value,
        },
        theme: "snow",
    }

    // initialize a new instance of the Quill editor
    // with the supplied options
    editor = new Quill(quillEditor.value as unknown as HTMLDivElement, editorConfig)

    // listen for changes and update the value
    editor.on("text-change", onTextChanged)

    valueChanged(value.value, undefined)
})

/*─────────────────────────────────────┐
│   tear down                          │
└─────────────────────────────────────*/
onUnmounted(() => {
    editor?.off("text-change", onTextChanged)
    editor = null
})
</script>

<template>
    <div class="quill">
        <div ref="quillToolbar">
            <div class="ql-toolbar">
                <span class="ql-formats">
                    <select class="ql-header ql-picker">
                        <option selected></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                    </select>
                </span>
                <span class="ql-formats">
                    <select class="ql-align">
                        <option></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                    <button type="button" class="ql-link"></button>
                    <button type="button" class="ql-clean"></button>
                </span>
                <div class="ql-formats">
                    <button type="button" class="ql-bold"></button>
                    <button type="button" class="ql-italic"></button>
                    <button type="button" class="ql-underline"></button>
                    <button type="button" class="ql-strike"></button>
                </div>
                <span class="ql-formats">
                    <select class="ql-color" title="Textfarbe"></select>
                    <select class="ql-background" title="Hintergrundfarbe"></select>
                </span>
                <div class="ql-formats">
                    <button type="button" class="ql-list" value="ordered"></button>
                    <button type="button" class="ql-list" value="bullet"></button>
                </div>
            </div>
        </div>

        <div v-show="!showSource" ref="quillEditor"></div>
        <textarea v-show="showSource" v-model="value" rows="10" class="form-control"></textarea>
        <button v-if="enableShowSource" type="button" class="btn btn-secondary mt-2" @click="toggleShowSource">
            Quelltext umschalten
        </button>
    </div>
</template>

<style lang="scss">
@import "./upstream/snow";

:root {
    display: flex;
    flex-direction: column;

    .ql-container {
        flex-grow: 1;
    }
}

.quill {
    border: 1px solid $input-border-color;
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.ql-editor {
    min-height: 200px;
    cursor: text;
}

.ql-editor ol li:before {
    content: "";
}

.ql-color-picker svg {
    height: 100%;
}

.ql-color.ql-picker.ql-color-picker {
    width: 40px;
    display: block !important;
}

.form-white {
    .ql-editor,
    .ql-toolbar {
        background-color: $light;
    }

    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #fff;
    }
}

.quill-light {
    &,
    .ql-editor,
    .ql-toolbar {
        background-color: $light;
    }

    .ql-toolbar.ql-snow {
        border-bottom: 1px solid #fff;
    }
}

.ql-editor .ql-align-justify {
    white-space: pre-line;
}
</style>
