<script setup lang="ts">
import { useToaster } from "@/vf"
import { storeToRefs } from "pinia"

const toaster = useToaster()
const { toasts } = storeToRefs(toaster)
</script>

<template>
    <div class="toaster">
        <div v-for="(toast, idx) in toasts" :key="idx" class="alert" :class="toast.alert_class">
            <button class="close float-right" @click="toaster.remove(toast)"><i class="fa fa-times"></i></button>
            <b>{{ toast.title }}</b>
            <div>{{ toast.message }}</div>
        </div>
    </div>
</template>

<style scoped>
.toaster {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    padding: 10px;
    width: 400px;
    max-width: 90vw;
}

.alert {
    margin-bottom: 10px;
}
</style>
