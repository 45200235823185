import { defineStore } from "pinia"
import { ref } from "vue"

export const useAcpPageState = defineStore("acp-page-state", () => {
    const sidebarOpen = ref(false)

    return {
        sidebarOpen,
        toggleSidebar: (value?: boolean) => (sidebarOpen.value = value ?? !sidebarOpen.value),
    } as const
})
