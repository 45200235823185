import CanSafeDeleteDialog from "@/acp/components/CanSafeDeleteDialog.vue"
import { reconfigureRoutes as acpReconfigureRoutes } from "./acp/router/reconfigureRoutes"
import { determineApiAddress } from "./vf"
import { defineVfConfig } from "./vf/config/VfConfig"

if (!import.meta.env.VITE_APP) {
    import.meta.env.VITE_APP = location.pathname.startsWith("/acp") ? "acp" : "shop"
}

const acp = import.meta.env.VITE_APP === "acp"
const shop = import.meta.env.VITE_APP === "shop"

export default defineVfConfig({
    http: {
        requestHandlers: [
            ({ client }) => {
                client.interceptors.request.use(function (config) {
                    config.baseURL = `//${determineApiAddress()}/api/${acp ? "backend" : "shop"}`

                    return config
                })
            },
            shop
                ? () => null
                : ({ client }) => {
                      const loginPath = import.meta.env.DEV ? "/acp/login" : "/login"

                      client.interceptors.response.use(null, async error => {
                          if (location.pathname === loginPath) {
                              return Promise.reject(error)
                          }

                          if (error.response.status === 401) {
                              location.href = loginPath
                              return error
                          }

                          return Promise.reject(error)
                      })
                  },
        ],
    },
    reconfigureRoutes(router, newRoutes) {
        if (acp) {
            acpReconfigureRoutes(router, newRoutes)
        }
    },
    safeDeleteDialog: {
        component: () => CanSafeDeleteDialog,
    },
})
