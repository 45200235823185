<script setup lang="ts">
import { useAppConfig, useHttpClient } from "@/vf"
import type { AxiosError } from "axios"
import { reactive, ref } from "vue"
import { useRouter } from "vue-router"
import { useGlobalState } from "../stores/useGlobalState"

const router = useRouter()
const http = useHttpClient()
const { refreshAppConfig } = useAppConfig()
const globalState = useGlobalState()

const error = ref()
const loading = ref(false)

const credentials = reactive({
    username: "",
    password: "",
})

async function submit() {
    error.value = ""
    loading.value = true
    try {
        await http.post("login", credentials)
        await refreshAppConfig()
        await globalState.refresh()

        await router.push("/")
    } catch (r) {
        const err = r as AxiosError<{ error: string }>

        console.log(err.response?.data.error)
        if (err.response?.data.error == "User account is disabled.") {
            error.value = "Das Adminkonto ist gesperrt"
        } else {
            error.value = "Login fehlgeschlagen"
        }
        setTimeout(() => (error.value = ""), 2000)
    }

    loading.value = false
}
</script>

<template>
    <!-- <div class="my-auto" v-if="!appConfig?.account"> -->
    <div class="card login-card my-auto" :class="{ 'shake-once': error }">
        <form @submit.prevent="submit">
            <div class="card-body">
                <h3 class="card-title">
                    <span class="text-primary">CANN24 ACP</span>
                    &middot; Login
                </h3>

                <div v-show="error" class="alert alert-danger my-3">{{ error }}</div>
                <!-- <div class="alert alert-success my-3" if.bind="activated" t="registration.activated"></div>
                        <div
                            class="alert alert-danger my-3"
                            if.bind="activationFailed"
                            t="registration.activationFailed"
                        ></div>
                        <div
                            class="alert alert-info my-3"
                            show.bind="forcedUsername"
                            t="login.forcedUsername"
                            t-params.bind="{forcedUsername: forcedUsername}"
                        ></div> -->

                <div class="my-4">
                    <label for="login_username">Benutzername</label>
                    <input
                        id="login_username"
                        v-model="credentials.username"
                        type="text"
                        class="form-control"
                        autocomplete="username"
                        :disabled="loading"
                        required
                    />
                </div>
                <div class="my-4">
                    <label for="login_password">Passwort</label>
                    <input
                        id="login_password"
                        v-model="credentials.password"
                        type="password"
                        class="form-control"
                        autocomplete="current-password"
                        :disabled="loading"
                        required
                    />
                </div>

                <div class="pt-4">
                    <button type="submit" class="btn btn-primary btn-block" :disabled="loading">Login</button>
                </div>
            </div>
        </form>
    </div>
    <!-- </div> -->
</template>
