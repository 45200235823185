import "@/acp/assets/style/main.scss"
import { useAppConfig } from "@/vf"
import { registerAnimateShow } from "@/vf/plugins/animate-show"
import { registerFocus } from "@/vf/plugins/focus"
import { plugin as formkit } from "@formkit/vue"
import { createPinia } from "pinia"
import { createApp, watch } from "vue"
import { createI18n } from "vue-i18n"
import App from "./App.vue"
import { createFormkitConfig } from "./formkit.config"
import router from "./router"
import { reconfigureRoutes } from "./router/reconfigureRoutes"

const app = createApp(App)

app.use(createPinia())

useAppConfig()
    .loadInitialAppConfig(router)
    .then(async appConfig => {
        watch(appConfig, newAppConfig => {
            if (!newAppConfig) {
                return
            }

            reconfigureRoutes(router, newAppConfig.routes)
        })

        reconfigureRoutes(router, appConfig.value?.routes ?? [])

        const i18n = createI18n({
            legacy: false,
            locale: "de",
            missingWarn: false,
            messages: {
                de: {
                    "crud:list.entries_per_page": "Einträge pro Seite",
                    "crud:button.new": "Anlegen",
                    "crud:list.search": "Suchen...",
                    "crud:list.page_info": "Seite {page} von {pageCount}, {totalItems} Einträge gesamt",
                    "crud:list.actions": "Aktionen",
                    "dialog.button.no": "Nein",
                    "dialog.button.yes": "Ja",
                    "dialog.button.cancel": "Abbrechen",
                    "dialog.button.ok": "OK",
                    "common:elements.password.generate_random_password": "Zufälliges Passwort generieren",
                    "common:elements.password.valuation.str-0": "Sehr schwaches Passwort",
                    "common:elements.password.valuation.str-1": "Schwaches Passwort",
                    "common:elements.password.valuation.str-2": "Mittelmäßiges Passwort",
                    "common:elements.password.valuation.str-3": "Gutes Passwort",
                    "common:elements.password.valuation.str-4": "Starkes Passwort",
                    "common:elements.password.valuation.str-5": "Sehr starkes Passwort",
                    "crud:delete.confirmation": "Wirklich löschen?",
                    "crud:delete.safe_delete_prompt": 'Zum Löschen bitte "{value}" eingeben.',
                    "crud:delete.confirmation_detail": "{type} {name} wirklich löschen?",
                    "crud:delete.success.title": "{type} gelöscht",
                    "crud:delete.success.message": "{type} {name} wurde erfolgreich gelöscht.",
                },
            },
        })

        registerAnimateShow(app)

        app.mixin({
            methods: {
                $t: i18n.global.t,
            },
        })

        app.use(formkit, createFormkitConfig(i18n.global))
        app.use(i18n)
        app.use(router)
        registerFocus(app)
        app.mount("body")
    })
