<template>
    <div class="container py-5 d-grid place-items-center">
        <div class="h-100 d-flex flex-column">
            <div class="text-center py-5 text-primary">
                <i class="far fa-5x fa-sad-cry"></i>
            </div>

            <p class="text-center">
                Da ist wohl was schief gelaufen... Unsere Techniker wurden benachrichtigt und arbeiten wahrscheinlich
                bereits an einer Lösung. Bitte probiere es später einfach erneut.
            </p>
        </div>
    </div>
</template>
