import { createRouter, createWebHistory } from "vue-router"
import LoginPage from "../pages/LoginPage.vue"
import NotFound from "../pages/NotFound.vue"

const router = createRouter({
    history: createWebHistory(import.meta.env.DEV ? "/acp" : "/"),
    routes: [
        // {
        //     path: "/",
        //     name: "home",
        //     component: HomeView,
        // },
        {
            path: "/:pathMatch(.*)*",
            component: NotFound,
            name: "_404",
            meta: { persistent: true },
        },
        {
            path: "/login",
            name: "login",
            component: LoginPage,
            meta: { persistent: true, bodyClass: "login", hideLayout: true },
            // settings: { bodyClass: "login", persistent: true },
        },
        // {
        //     path: "/select",
        //     name: "@ApeisiaLoginAccess.Security.select",
        //     moduleId: this.accountSelectModuleId,
        //     settings: { bodyClass: "login", persistent: true },
        // },
    ],
})

// const _resolve = router.resolve
// router.resolve = function (to: RouteLocationRaw, current): RouteLocation & { href: string } {
//     // navigation instruction params (as called by router.push)
//     let params: RouteParamsRaw | undefined = undefined
//     if (typeof to === "object" && "params" in to) {
//         params = to.params
//     }

//     // resolve with the given params
//     const resolved = _resolve.call(router, to, current)
//     let foundQueryParams = false

//     // check for all instruction params if they exist on the resolved route location
//     for (const [key, value] of Object.entries(params ?? {})) {
//         if (typeof value === "string" && !(key in resolved.params)) {
//             resolved.query[key] = value
//             foundQueryParams = true
//         }
//     }

//     if (foundQueryParams) {
//         // when params / query were changed, resolve again to update the href
//         return _resolve(resolved)
//     }

//     return resolved
// }

router.afterEach((to, from) => {
    if (typeof from.meta.bodyClass === "string") {
        document.body.classList.remove(from.meta.bodyClass)
    }

    if (typeof to.meta.bodyClass === "string") {
        document.body.classList.add(to.meta.bodyClass)
    }
})

export default router
