<script setup lang="ts">
import { useApp, useAppConfig, useDialogs, type AppConfigMenuNode } from "@/vf"
import { computed } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useAcpPageState } from "../stores/useAcpPageState"
import { useGlobalState } from "../stores/useGlobalState"

const { appConfig } = useAppConfig()
const { logout } = useApp()
const dialogs = useDialogs()
const globalState = useGlobalState()
const route = useRoute()
const router = useRouter()

async function signout() {
    if (await dialogs.confirm("Möchten Sie sich wirklich abmelden?")) {
        logout()
    }
}

function findBestMatchMenuItem(menuItems: AppConfigMenuNode[], currentUrl: string): AppConfigMenuNode | null {
    let bestMatchMenuItem: AppConfigMenuNode | null = null
    let bestMatchLength = 0

    for (const menuItem of menuItems) {
        if (!menuItem.route) {
            continue
        }

        const menuItemUrl = router.resolve({ name: menuItem.route }).href
        const matchLength = currentUrl.startsWith(menuItemUrl) ? menuItemUrl.length : 0

        if (matchLength > bestMatchLength) {
            bestMatchMenuItem = menuItem
            bestMatchLength = matchLength
        }
    }

    return bestMatchMenuItem
}

const currentPath = computed(() => (router.options.history.base ?? "") + (route.path ?? ""))
const bestMatch = computed(() => findBestMatchMenuItem(appConfig.value?.menu ?? [], currentPath.value))

const pageState = useAcpPageState()

function navigateAndClose(event: MouseEvent, href: string, navigate: (event: MouseEvent) => any) {
    pageState.toggleSidebar(false)
    navigate(event)
}
</script>

<template>
    <div v-if="appConfig && !route.meta.hideLayout" class="acp-sidebar">
        <img src="@/acp/assets/cann24.svg" alt="CANN24" class="acp-sidebar-logo" />

        <div class="sidebar-menu">
            <RouterLink
                v-for="item of appConfig?.menu ?? []"
                :key="item.id"
                v-slot="{ href, navigate }"
                :to="{ name: item.route }"
                :custom="true"
                @click="pageState.toggleSidebar(false)"
            >
                <a
                    :href="href"
                    class="sidebar-menu-link"
                    :class="item === bestMatch ? 'active' : ''"
                    @click="navigateAndClose($event, href, navigate)"
                >
                    <div>
                        <i class="fa-fw" :class="item.settings?.icon"></i>
                        {{ item.label }}
                    </div>

                    <div
                        v-if="
                            item.route === '@Backend.Customer.index_new_customers' &&
                            (globalState.data?.newCustomers ?? 0) > 0
                        "
                    >
                        <span class="badge badge-primary">{{ globalState.data?.newCustomers }}</span>
                    </div>

                    <div v-if="item.route === '@Backend.Order.index' && (globalState.data?.openOrders ?? 0) > 0">
                        <span class="badge badge-primary">{{ globalState.data?.openOrders }}</span>
                    </div>
                </a>
            </RouterLink>
            <div class="sidebar-menu-item">
                <a href="#" class="sidebar-menu-link" @click="signout">
                    <div>
                        <i class="fas fa-fw fa-sign-out-alt"></i>
                        Abmelden
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>
