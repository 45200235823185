import { createMultiStepPlugin } from "@formkit/addons"
import { de } from "@formkit/i18n"
import { generateClasses } from "@formkit/themes"
import { createInput, defaultConfig as formkitDefaultConfig } from "@formkit/vue"
import type { Composer } from "vue-i18n"
import QuillEditor from "@/acp/components/quill/QuillEditor.vue"

// noinspection SpellCheckingInspection
export const createFormkitConfig = (i18n: Composer<any, any, any>) =>
    formkitDefaultConfig({
        locales: { de },
        locale: "de",
        config: {
            classes: generateClasses({
                "global": {
                    input: "$reset form-control",
                    outer: "$reset form-group",
                    wrapper: "$reset flex-grow-1",
                    label: "$reset col-form-label",
                    inner: "$reset flex-grow-1",
                    message: "$reset alert alert-danger",
                    help: "$reset small text-muted form-text",
                    // decorator: "$reset form-toggler",
                },
                "multi-step": {
                    outer: "$reset",
                    wrapper: "$reset",
                    tabs: "$reset nav nav-tabs mb-4",
                    tab: "$reset nav-item nav-link multi-step-tab d-flex justify-content-center align-items-center",
                    badge: "$reset badge badge-danger ml-2 p-2",
                },
                "checkbox": {
                    outer: "$reset form-group",
                    input: "$reset mr-1",
                    inner: "$reset d-inline-block",
                    label: "$reset",
                    wrapper: "$reset",
                },
                "file": {
                    fileRemove: "$reset btn btn-danger btn-sm ml-3",
                },
            }),
        },
        plugins: [
            createMultiStepPlugin(),
            // translation
            node => {
                if (node.props.__FormKitIsTranslated) {
                    return
                }

                if (node.props.label) {
                    node.props.label = i18n.t(node.props.label)
                    node.props.__FormKitIsTranslated = true
                }

                if (node.props.options) {
                    // const props = node.props
                    const options = []

                    for (const option of node.props.options) {
                        options.push({
                            value: option.value,
                            label: i18n.t(option.label),
                        })
                    }

                    node.props.options = options
                    node.props = new Proxy(node.props, {
                        set(target, key, value) {
                            if (key === "options") {
                                return true
                            }

                            Object.assign(target, { [key]: value })
                            return true
                        },
                    })
                }
            },
            // section schema on checkbox
            // node => {
            //     // noinspection SpellCheckingInspection
            //     const patchSectionSchema = {
            //         checkbox: {
            //             outer: { $el: "div", attrs: { class: "checkbox" } },
            //             wrapper: { $el: "label" },
            //             inner: { $el: null },
            //             label: { $el: "span" },
            //             decorator: { $el: "div", attrs: { class: "form-toggler" } },
            //             fieldset: { $el: null },
            //             legend: { $el: "label", attrs: { class: null } },
            //         },
            //         $translations: {
            //             // "wrapper": {
            //             //     $el: null,
            //             //     $cmp: 'TranslationsForm',
            //             // },
            //         },
            //     }

            //     node.on("created", ({ payload: node }) => {
            //         if (node.props?.type in patchSectionSchema || "$" + node.name in patchSectionSchema) {
            //             const patch =
            //                 patchSectionSchema[node.props?.type as keyof typeof patchSectionSchema] ||
            //                 patchSectionSchema[("$" + node.name) as keyof typeof patchSectionSchema]
            //             // Let's retain our own copy of this definition to prevent deep object referencing
            //             const definition = { ...node.props.definition }
            //             const schema = definition.schema

            //             // We replace the schema function with our own higher-order-function
            //             definition.schema = function (extensions = {}) {
            //                 const ext = {
            //                     ...extensions,
            //                     ...patch,
            //                 }
            //                 // Finally, we call the original schema, with our extensions applied
            //                 return schema(ext)
            //             }

            //             // Now we replace the input definition
            //             node.props.definition = definition
            //         }
            //     })
            // },
            // required = bold label
            node => {
                if (node.props.parsedRules?.find((rule: any) => rule.name === "required") && node.context) {
                    node.context.classes.label = "col-form-label required"
                }
            },
        ],
        inputs: {
            // checkbox: createInput(CheckboxToggle),
            quill: createInput(QuillEditor, { type: "input" }),
        },
    })
