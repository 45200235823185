<script lang="ts" setup>
import type { DialogController } from "@/vf"
import { ref, toRefs } from "vue"

const props = defineProps<{ controller: DialogController<any> }>()

const { controller } = toRefs(props)
const { requiredValue, title, message } = controller.value.props

const value = ref("")

function submit() {
    if (value.value != requiredValue) {
        return
    }

    controller.value.ok(value.value)
}
</script>

<template>
    <form class="dialog-flush p-0" @submit.prevent="submit()">
        <h4 class="mb-4 p-4 bg-danger text-white">
            {{ title }}
        </h4>

        <div class="px-3">
            <div class="">
                <label v-t="{ path: message, args: { value: requiredValue } }" for="dialogInput"></label>
                <input id="dialogInput" v-model="value" type="text" class="form-control" />
            </div>
        </div>

        <div class="d-flex mt-4 p-3 justify-content-end border-top">
            <button
                v-t="'dialog.button.cancel'"
                type="button"
                class="btn btn-secondary dialog-footer-btn"
                @click="controller.cancel()"
            ></button>
            <button
                v-t="'dialog.button.ok'"
                type="submit"
                class="btn btn-danger dialog-footer-btn btn-raised"
                :disabled="value != requiredValue"
            ></button>
        </div>
    </form>
</template>
