<script setup lang="ts">
import { VfDialogContainer, useAppConfig, useHttpClient } from "@/vf"
import { RouterView, useRoute } from "vue-router"
import BeatLoader from "vue-spinner/src/BeatLoader.vue"
import AcpSidebar from "./components/AcpSidebar.vue"
import CanToaster from "./components/CanToaster.vue"
import StagingWatermark from "./StagingWatermark.vue"
import { useAcpPageState } from "./stores/useAcpPageState"

const { runningHttpRequests } = useHttpClient()

const { appConfig } = useAppConfig()
const route = useRoute()

const pageState = useAcpPageState()
const isStaging = import.meta.env.VITE_ENVIRONMENT === "staging"
</script>
<template>
    <StagingWatermark v-if="isStaging" />
    <div class="acp-container" :class="{ 'acp-sidebar-collapsed': !pageState.sidebarOpen }">
        <button
            v-if="appConfig && !route.meta.hideLayout"
            class="acp-sidebar-button"
            @click="pageState.toggleSidebar()"
        >
            <i class="fa" :class="pageState.sidebarOpen ? 'fa-times' : 'fa-bars'"></i>
        </button>

        <AcpSidebar />

        <div class="acp-content">
            <RouterView v-slot="{ Component, route: currentRoute }">
                <Transition>
                    <Suspense>
                        <component :is="Component" :key="currentRoute.path" />
                    </Suspense>
                </Transition>
            </RouterView>
        </div>
    </div>

    <VfDialogContainer>
        <template #fallback>
            <div class="d-flex justify-content-center my-5">
                <BeatLoader color="#89bcb3" />
            </div>
        </template>
    </VfDialogContainer>

    <CanToaster />

    <!-- <Suspense>
        <template #default><AppRouter /></template>
        <template #fallback>
            <div class="fixed top-0 left-0 right-0 bottom-0 grid z-50 isolate place-items-center">
                <GridLoader color="#a5015b" />
            </div>
        </template>
    </Suspense>
    -->
    <!-- 
    <Transition
        enter-active-class="transition duration-500 delay-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
    >
        <div class="fixed top-0 left-0 right-0 bottom-0 grid z-50 isolate" v-if="runningHttpRequests > 0">
            <div class="col-start-1 col-span-1 row-start-1 row-span-1 z-10 bg-secondary opacity-50"></div>
            <div class="col-start-1 col-span-1 row-start-1 row-span-1 z-20 grid place-items-center">
                <BeatLoader color="#89bcb3" />
            </div>
        </div>
    </Transition> -->

    <div class="http-spinner" :class="{ active: runningHttpRequests > 0 }">
        <BeatLoader color="#89bcb3" />
    </div>
</template>

<style scoped>
.http-spinner {
    position: fixed;
    inset: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    place-items: center;
    z-index: 50;
    background-color: #33333300;
    pointer-events: none;
    opacity: 0;
    transition: background-color 250ms, opacity 250ms;
    transition-delay: 200ms;
}

.http-spinner.active {
    background-color: #33333350;
    pointer-events: all;
    opacity: 1;
}
</style>
